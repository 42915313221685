import React from "react";
import {Button, Modal} from "semantic-ui-react";

const ConfirmationModal = ({confirmation, dispatch, finalize}) => {
    const { open } = confirmation;

    return (
        <>
            <Button className="float-right" color="blue" onClick={() => {
                finalize();
                dispatch({type: 'open'})
            }}>
                Send Alert
            </Button>

            <Modal
                size="mini"
                open={open}
                onClose={() => dispatch({ type: 'close' })}
            >
                <Modal.Header>Send the alert</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to send the alert?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => dispatch({ type: 'close' })}>
                        No
                    </Button>
                    <Button positive onClick={() => dispatch({ type: 'send' })}>
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        </>);
}

export default ConfirmationModal;