/**
 * Environment Context
 *
 * This provides functions to be used in components to determine details
 * about the environment that the app is running in and make adjustments accordingly.
 */
import React, {createContext} from "react";

const EnvironmentContext = createContext({});
const Environment = ({children}) => {
    /**
     * getAppEnv
     *
     * Get the app's current environment (prod, beta, or dev).
     *
     * @returns {string}
     */
    const getAppEnv = () => {
        let host = window.location.hostname;
        if (host === "avalerts.org" || host === "www.avalerts.org") {
            return "prod";
        } else if (host === "beta.avalerts.org") {
            return "beta";
        } else {
            return "dev";
        }
    }

    /**
     * getHostName
     *
     * Gets the hostname (domain name) for the current environment.
     * @returns {string}
     */
    const getHostName = () => {
        switch (getAppEnv()) {
            case "prod":
                return "www.avalerts.org";
            case "beta":
                return "beta.avalerts.org";
            default:
                return "dev.avalerts.org";
        }
    }

    /**
     * Make Alert URI
     *
     * Construct the alert URI based on inputs. If correct, the URI can be used
     * to trigger sending alerts.
     */
    const getAlertURI = (msg, severity, selectors) => {
        let env = getAppEnv();
        switch (env) {
            case "prod":
                return `https://prod-api.avalerts.org/web_send_alert?message=${encodeURIComponent(msg)}&message_type=${severity}&message_selectors=${selectors}`;
            case "beta":
                return `https://beta-api.avalerts.org/web_send_alert?message=${encodeURIComponent(msg)}&message_type=${severity}&message_selectors=${selectors}`;
            default:
                return `https://dev-api.avalerts.org/web_send_alert?message=${encodeURIComponent(msg)}&message_type=${severity}&message_selectors=${selectors}`;
        }
    }

    /**
     * Get the responses spreadsheet link depending on the environment.
     */
    const getSpreadsheetLink = () => {
        switch (getAppEnv()) {
            case "prod":
                return "https://docs.google.com/spreadsheets/d/1x2KDdvNKeYxkdR_qWBD9gBONOd82mG7ZePJS9fHtngI/edit#gid=0";
            case "beta":
                return "https://docs.google.com/spreadsheets/d/1Clowq5nthREH-pIgzfhlTgE8k8gtHUHTAv-AJ4-DeBg/edit#gid=0";
            default:
                return "https://docs.google.com/spreadsheets/d/1Hd-bfisHdpyx4hfYSa6ceGmV-kOGfu3O8WPvTBDA3_g/edit#gid=0";
        }
    }

    /**
     * Retrieve the correct API endpoint depending on the environment.
     *
     * Gets the data for the number of people who will receive the alert.
     *
     * @returns {string}
     */
    const getSendCountsLink = () => {
        switch (getAppEnv()) {
            case "prod":
                return "https://prod-api.avalerts.org/get_potential_send_counts";
            case "beta":
                return "https://beta-api.avalerts.org/get_potential_send_counts";
            default:
                return "https://dev-api.avalerts.org/get_potential_send_counts";
        }
    }

    /**
     * Get the API endpoint for past alerts
     *
     * Depends on the current environment.
     *
     * @returns {string}
     */
    const getPastAlertsEndpoint = () => {
        switch (getAppEnv()) {
            case "prod":
                return "https://prod-api.avalerts.org/get_past_alerts";
            case "beta":
                return "https://beta-api.avalerts.org/get_past_alerts";
            default:
                return "https://dev-api.avalerts.org/get_past_alerts";
        }
    }

    return (
        <EnvironmentContext.Provider value={{getAppEnv, getHostName, getAlertURI, getSpreadsheetLink, getSendCountsLink, getPastAlertsEndpoint}}>
            {children}
        </EnvironmentContext.Provider>
    );
};

export {Environment, EnvironmentContext};