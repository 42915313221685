import {useContext} from "react";
import {EnvironmentContext} from "../context/Environment";
import useAPI from "../useAPI";
import {List, Loader, Button} from "semantic-ui-react";

const AlertDispatch = ({message, severity, delivery, dispatch, setShowPreview, setShowNow, setMessage, setSendEmails, setSendTexts, setMakeCalls}) => {
    const {getAlertURI, getSpreadsheetLink} = useContext(EnvironmentContext);
    const {loading, data, error} = useAPI(getAlertURI(message, severity ? "EVAC" : "FYI", delivery));
    const spreadSheetLink = getSpreadsheetLink();

    if (loading) {
        return <Loader active/>;
    } else if (data) {
        return <div className="p-8">
            <h3>Alert successfully queued for delivery!</h3>
            <List className="text-left m-auto w-3/6">
                <List.Item icon='mobile alternate' content={`${data["num_text_success"]} texts sent, ${data["num_text_fail"]} failed`} />
                <List.Item icon='mail' content={`${data["num_email_success"]} emails sent, ${data["num_email_fail"]} failed`} />
                <List.Item
                    icon='phone'
                    content={`${data["num_general_calls_queued"] + data["num_ananda_bell_calls_queued"]} calls queued`}
                />
            </List>
            {severity &&
            <div className="text-left mb-3"><a href={spreadSheetLink} rel="noreferrer" target="_blank"><Button secondary>Open responses
                spreadsheet</Button></a></div>}
            <div>
                <Button primary onClick={() => {
                    dispatch({type: "reset"})
                    setShowPreview(false);
                    setSendEmails(false);
                    setSendTexts(false);
                    setMakeCalls(false);
                    setMessage("");
                    setShowNow(true);
                }}>Done</Button></div>
        </div>;
    } else {
        return <pre>{JSON.stringify(error)}</pre>;
    }
}

export default AlertDispatch;