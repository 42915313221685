import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/* Amplify libraries */
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: 'us-west-1',
    userPoolId: "us-west-1_PFkfzb3IQ",
    userPoolWebClientId: "63q4fo9dp9b7nfeudov2qs2ovj"
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
