import React, {useContext, useEffect, useReducer, useState} from "react";
import useAPI from "../useAPI";
import useUserInfo from "../useUserInfo";
import {Button, Checkbox, Form, Icon, Loader, Message, TextArea} from "semantic-ui-react";
import {AccountContext} from "../context/Account";
import ConfirmationModal from "./ConfirmationModal";
import AlertDispatch from "./AlertDispatch";
import {EnvironmentContext} from "../context/Environment";
import Login from "./Login";
import Now from "./Now";

const NewSendAlert = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [showNow, setShowNow] = useState(true);
    const [showPreview, setShowPreview] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const {getSendCountsLink} = useContext(EnvironmentContext);
    const {loading, data, error} = useAPI(getSendCountsLink());
    const {username, email, phoneNumber} = useUserInfo();
    const [makeCalls, setMakeCalls] = useState(false);
    const [sendTexts, setSendTexts] = useState(false);
    const [sendEmails, setSendEmails] = useState(false);
    const [message, setMessage] = useState("");
    const [preparedMessage, setPreparedMessage] = useState("");
    const {logout, getSession, authError} = useContext(AccountContext);
    const [userError, setUserError] = useState("");
    const [evacuation, setEvacuation] = useState(false);
    const [deliveryCode, setDeliveryCode] = useState(0);
    const [confirm, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "open":
                return {
                    open: true, send: false
                }
            case "close":
                return {
                    open: false, send: false
                }
            case "send":
                return {
                    open: false, send: true
                }
            default:
                return {
                    open: false, send: false
                }
        }
    }, {
        open: false, send: false
    }, undefined);

    useEffect(() => {
        if (!loggedIn) {
            getSession()
                .then(session => {
                    setLoggedIn(true);
                    console.log("session: ", session);
                })
                .catch(() => {
                    console.log("The user wasn't logged in.");
                });
        }
    }, [getSession, loggedIn]);

    const finalizeAlert = () => {
        let msg = `${message} - From ${username[0].toUpperCase() + username.substring(1)}`;
        let selectors = 0;
        if (sendTexts) selectors += 1;
        if (sendEmails) selectors += 2;
        if (makeCalls) selectors += 4;
        // finalize values
        setDeliveryCode(selectors);
        setPreparedMessage(msg);
    };

    const onShowPreview = e => {
        e.preventDefault();
        if (message === "") {
            setUserError("Please enter a message first.");
        } else if (!sendTexts && !sendEmails && !makeCalls) {
            setUserError("Please select destinations first.");
        } else {
            setShowPreview(!showPreview);
            setUserError(undefined);
        }
    };

    return (<>
        <div className="max-h-screen mx-auto md:w-3/6 lg:w-2/5">
            {!loggedIn && authError && !showNow && <Message negative content={authError}/>}
            {loggedIn && showSettings && !showNow && <h2 className="text-3xl text-center">Settings</h2>}
            {loggedIn && !showSettings && showPreview && !showNow && !confirm.send &&
                <h2 className="text-3xl text-center">Preview</h2>}
            {loggedIn && !showSettings && !showPreview && !showNow &&
                <h2 className="text-3xl text-center">Send Alert</h2>}
            {loggedIn && confirm.send && <h2 className="text-3xl text-center">Sent Alert</h2>}

            {(() => {

                if (showNow) {
                    return <Now/>
                }

                if (!loggedIn) {
                    return <Login setLoggedIn={setLoggedIn}/>
                } else if (error) {
                    return <Message negative className="text-left" header="Unable to connect to AVA."
                                    content="Please check your network connection. If this error persists, contact an AVA developer."/>
                } else if (showSettings) {
                    return <div className="m-auto p-5 text-left">
                        <div className="my-5"><p>You are logged in as: <b>{username}</b></p></div>
                        <div className="my-5"><p><Icon name="mail"/> {email}</p></div>
                        <div className="my-5"><p><Icon name="phone"/> {phoneNumber}</p></div>
                        <div className="my-5"><p>To change your email address, mobile phone number, or password,
                            contact an AVA developer.</p>
                        </div>
                        <Button basic color="black" className="float-left" onClick={() => {
                            logout();
                            setLoggedIn(false);
                        }}>Logout</Button>
                    </div>
                } else if (showPreview) {
                    if (!confirm.send) {
                        return <div className="p-8">
                            {evacuation ? <h4 className="text-red-600 text-center text-2xl">Evacuation</h4> :
                                <h4 className="text-green-500 text-center text-2xl">FYI</h4>}
                            <div className="m-auto mb-3">
                                <ul className="text-left mx-auto">
                                    {sendTexts && <li><Icon name="mobile alternate"/>Send {data["texts"]} texts</li>}
                                    {sendEmails && <li><Icon name="mail"/>Send {data["emails"]} emails</li>}
                                    {makeCalls && <li><Icon name="phone"/>Make {data["calls"]} calls</li>}
                                </ul>
                            </div>
                            <div className="p-3 my-3 shadow">
                                <p className="text-left">From Ananda Village Alerts System: </p>
                                <p className="text-left whitespace-pre-line">{message}
                                {username && <span className="text-left"> -
                                    From {username[0].toUpperCase() + username.substring(1)} 🙏</span>}</p>
                                {evacuation ? <div className="mt-5"><p className="text-left">Reply:<br/>
                                        1 - I understand and am evacuating<br/>
                                        2 - I need help<br/>
                                        3 - Choosing not to evacuate<br/>
                                        4 - Not on-site<br/>
                                        5 - Wrong number; not part of Ananda<br/></p></div> :
                                    <div><p className="text-left mt-5">(FYI only, no reply requested.)</p></div>}
                            </div>
                            <div className="float-right">
                                <Button color="standard" onClick={() => setShowPreview(!showPreview)}>Go
                                    back</Button>
                                <ConfirmationModal finalize={finalizeAlert} confirmation={confirm}
                                                   dispatch={dispatch}/>
                            </div>
                        </div>;
                    } else {
                        return <AlertDispatch message={preparedMessage} severity={evacuation}
                                              delivery={deliveryCode}
                                              dispatch={dispatch} setShowPreview={setShowPreview}
                                              setShowNow={setShowNow} setMessage={setMessage}
                                              setMakeCalls={setMakeCalls}
                                              setSendEmails={setSendEmails}
                                              setSendTexts={setSendTexts}
                        />;
                    }
                } else {
                    return <>
                        {userError && <Message warning content={userError}/>}
                        <Form name="sendAlertForm" onSubmit={finalizeAlert} className="my-3 mx-auto p-8">
                            <div className="mx-auto text-center">
                                <Button.Group size='small' onClick={e => e.preventDefault()}>
                                    <Button positive={!evacuation} onClick={() => setEvacuation(false)}>FYI</Button>
                                    <Button.Or/>
                                    <Button negative={evacuation}
                                            onClick={() => setEvacuation(true)}>Evacuation</Button>
                                </Button.Group>
                            </div>

                            {loading && !error ?

                                <div className="mt-10 text-center">
                                    <Loader active inline/>
                                    <p className="mt-5">Retrieving send counts</p>
                                </div>

                                :

                                <ul className="text-left my-10">
                                    <li><Checkbox onChange={() => setSendTexts(!sendTexts)} checked={sendTexts}
                                                  type="checkbox"
                                                  name="texts"
                                                  label={`Text messages (will send ${data["texts"]})`}/></li>
                                    <li><Checkbox onChange={() => setSendEmails(!sendEmails)} checked={sendEmails}
                                                  type="checkbox" name="emails"
                                                  label={`Emails (will send ${data["emails"]})`}/></li>
                                    <li><Checkbox onChange={() => setMakeCalls(!makeCalls)} checked={makeCalls}
                                                  type="checkbox"
                                                  name="calls" label={`Phone calls (will make ${data["calls"]})`}/>
                                    </li>
                                </ul>}

                            <h4 className="text-left">From Ananda Village Alerts System:</h4>
                            <Form.Field className="my-3">
                                <TextArea onChange={e => setMessage(e.target.value)} value={message}
                                          placeholder="Write your message"/>
                                {evacuation ? <div className="my-3"><p
                                    className="text-left">- {username && username[0].toUpperCase() + username.substring(1)}</p>
                                    <p className="text-left">Reply:<br/>
                                        1 - I understand and am evacuating<br/>
                                        2 - I need help<br/>
                                        3 - Choosing not to evacuate<br/>
                                        4 - Not on-site<br/>
                                        5 - Wrong number; not part of Ananda<br/></p></div> : <><p
                                    className="text-left mt-3">- {username && username[0].toUpperCase() + username.substring(1)}</p>
                                    <p className="text-left">(FYI only, no reply requested.)</p></>}
                            </Form.Field>
                            <Form.Field className="my-3">
                                <Button primary onClick={onShowPreview} className="float-right">Preview</Button>
                            </Form.Field>
                        </Form>
                        <br/>
                    </>
                }

            })()}


        </div>

        <div className="mx-auto text-left p-8 w-100 sm:w-3/6 lg:w-2/5">


            {!loggedIn && !showNow && <div className="mt-1 ">
                <div className="text-left cursor-pointer underline text-sky-600"
                     onClick={() => setShowNow(true)}>Happening Now
                </div>
            </div>}
        </div>

        <footer className="fixed bottom-0 w-screen p-0 m-0">
            {(showNow || !confirm.send) && <div className="pl-2 mx-auto md:w-3/6 lg:w-2/5 text-left pb-6"><p><a
                className="underline text-left text-sky-600"
                href="https://www.notion.so/anandafamily/Leader-Announcements-Guide-dff1389fcff24ca1a914bb0a93a01a1d"
                target="_blank" rel="noreferrer">Leader's announcement
                guide</a></p></div>}
            <nav className="text-center bg-gray-200 h-full w-full m-0">
                <div className="flex-center space-x-5">

                    <Icon.Group className="p-3 cursor-pointer" onClick={() => {
                        setShowNow(true);
                    }}>
                        <Icon name="info circle" size="big"/>
                        <br/>
                        <span>Now</span>
                    </Icon.Group>

                    <Icon.Group className="cursor-pointer p-3" onClick={() => {
                        setShowNow(false);
                        if (loggedIn) {
                            setShowSettings(false);
                            setShowPreview(false);
                        }
                    }}>
                        <Icon name="bell" size="big"/>
                        <br/>
                        <span>Send Alert
                    </span>
                    </Icon.Group>

                    <Icon.Group className="cursor-pointer p-3" onClick={() => {
                        setShowNow(false);
                        if (loggedIn) {
                            setShowSettings(!showSettings)
                        }
                    }}>
                        <Icon name="setting" size="big"/>
                        <br/>
                        <span>Settings</span>
                    </Icon.Group>

                    <Icon.Group className="cursor-pointer p-3" onClick={() => {
			window.open('./ToS.html', '_blank');
                    }}>
                        <Icon name="legal" size="big"/>
                        <br/>
                        <span>Terms</span>
                    </Icon.Group></div>
            </nav>
        </footer>

    </>)

}


export default NewSendAlert;
