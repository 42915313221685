import logo from "../cropped-Spiritual-Eye-Joy-Logo-C.jpg";
import {useContext} from "react";
import {EnvironmentContext} from "../context/Environment";
import { Icon } from "semantic-ui-react";
const Header = () => {
    const { getAppEnv } = useContext(EnvironmentContext);
    const appEnv = getAppEnv() === "prod" ? "" : "(" + getAppEnv() + ")";


    return <>
        {(getAppEnv() === "beta" || getAppEnv() === "dev") &&
            <>
                <div className="bg-yellow-300 w-100 fixed top-0 left-0 right-0 text-center"><Icon name="warning sign"/>This site is for testing only. Please
                    use the <a href="https://avalerts.org">production site</a> for a real emergency.</div><br/><br/>
            </>
        }
        <header className="App-header">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-normal leading-normal mt-0 mb-2">AVA — Ananda Village Alerts
                {appEnv.toUpperCase()}</h1>
            <img src={logo} className="App-logo" alt="logo"/>
        </header>
    </>
}

export default Header;