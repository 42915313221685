import './App.css';
import {Account} from "./context/Account";
import {Environment} from "./context/Environment";
import Header from "./components/Header";
import NewSendAlert from "./components/NewSendAlert";

function App() {
    return (
        <>
            <div className="h-screen">
                <Environment>
                    <Account>
                        <Header />
                        <NewSendAlert/>
                    </Account>
                </Environment>
            </div>
        </>
    );
}

export default App;
