import React, {createContext, useState} from "react";
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import Pool from "../UserPool";

const AccountContext = createContext({});
const Account = (props) => {
    const [authError, setAuthError] = useState(""); // hold any login error messages

    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username, Pool
            });

            const authDetails = new AuthenticationDetails({
                Username, Password
            });

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    setAuthError("");
                    resolve(data);
                }, onFailure: (err) => {
                    setAuthError("We were unable to log you in. Check your username and password and try again.");
                    reject(err);
                }
            });
        });
    }

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        reject();
                    } else {
                        resolve(session);
                    }
                });
            } else {
                reject();
            }
        });
    };

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
        }
    }

    return (
        <AccountContext.Provider value={{ authenticate, getSession, logout, authError }}>
            {props.children}
        </AccountContext.Provider>
    );
};

export {Account, AccountContext};