import {useContext, useEffect, useState} from "react";
import {Dimmer, Grid, Icon, Loader} from "semantic-ui-react";
import {EnvironmentContext} from "../context/Environment";

const removeLeadingZero = (str) => {
    if (str.toString().charAt(0) === "0") {
        return str.slice(1);
    }

    return str;
}

/**
 *
 * Output "Today" or "Yesterday" instead of date if the
 * date is within the last 48 hours.
 *
 * Otherwise, return the date in the format mm/dd/yy.
 *
 */
const maybeTodayOrYesterday = date => {
    const hoursInMil24 = 86400000;
    const hoursInMil48 = 172800000;
    const currentDate = new Date().getTime();
    const eventDate = new Date(date).getTime();

    if ((currentDate - hoursInMil24) <= eventDate) {
	return "Today";
    } else if ((currentDate - hoursInMil48) <= eventDate) {
	return "Yesterday";
    } else {
        let parsedDate = new Date(date);
	let day = parsedDate.getDate().toString().padStart(2, "0");
	let month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
	let year = parsedDate.getFullYear().toString().slice(-2);
	
	return `${month}/${day}/${year}`;
    }
};

const parseDate = dateStr => {
    let dateArr = dateStr.split(" ");
    let day = dateArr[0];
    let date = maybeTodayOrYesterday(dateArr[1]);
    let time = removeLeadingZero(dateArr[2]);
    let fullDate;

    if (date === "Today") {
        fullDate = "Today";
    } else if (date === "Yesterday") {
        fullDate = "Yesterday"
    } else {
        date = removeLeadingZero(date);
        fullDate = `${day} ${date}`;
    }

    return {time, date, day, fullDate};
};

const printMsgTypes = (text, email, call) => {
    let arr = [];
    if (text) arr.push("text");
    if (email) arr.push("email");
    if (call) arr.push("call");
    let str = arr.join(", ")
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const printEvacuation = (type) => {
    if (type !== "FYI") {
        return <div className="text-left">
            <Icon fitted name="warning" className="text-red-500"/> Evacuation
        </div>
    }
}

const renderData = data => {
    return data.map((v) => {
        return <Grid.Row className="border-t-2 border-black">
            <Grid.Column width={6}>
                <div className="text-left">{parseDate(v.datetime).fullDate}</div>
                <div className="text-left">{parseDate(v.datetime).time}</div>
                <div className="text-left">{printMsgTypes(v.send_text, v.send_email, v.make_call)}</div>
                {printEvacuation(v.msg_type)}
            </Grid.Column>
            <Grid.Column width={9}>
                <div className="text-left">{v.msg}</div>
            </Grid.Column>
        </Grid.Row>;
    });
};

const Now = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { getPastAlertsEndpoint } = useContext(EnvironmentContext);

    useEffect(() => {
        fetch(getPastAlertsEndpoint(), {
            method: "GET"
        })
            .then(res => res.json())
            .then(setData)
            .then(() => setLoading(false))

    }, []);

    useEffect(() => {
        console.log(data);
    }, [data]);

    return <>
        {loading ? <Dimmer active inverted><Loader inverted>Loading</Loader></Dimmer> :
            <section className="overflow-scroll max-h-snug lg:max-h-snuggle mx-7 my-3 sm:w-5/6 lg:w-5/6">
                <h2 className="text-center text-2xl mt-0">Happening Now</h2>
                <h4 className="text-left text-decoration-underline my-10 font-bold">Latest Announcements:</h4>
                    <Grid className="py-0">
                        {data && renderData(data)}
                    </Grid>
            </section>}
    </>
}

export default Now;
