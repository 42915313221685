/**
 * useAPI
 *
 * Custom React hook that calls the AVA api.
 */
import { useState, useEffect, useContext } from "react";
import { AccountContext } from "./context/Account";

const useAPI = (endpoint) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                let {jwtToken} = session.getIdToken();
                setToken(jwtToken);
            });
    });

    useEffect(() => {
        if (!endpoint) return;
        if (!token) return;

        const init = {
            method: "GET",
            headers: {"Authorization": token}
        }

        fetch(endpoint, init)
            .then(res => {
                return res.json();
            })
            .then(setData)
            .then(() => setLoading(false))
            .catch(setError);
    }, [endpoint, token]);

    return { loading, data, error };
}

export default useAPI;