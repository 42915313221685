import { useEffect, useState, useContext } from "react";
import { AccountContext } from "./context/Account";

const useUserInfo = () => {
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then((session) => {
                let {payload} = session.getIdToken();
                setUsername(payload["cognito:username"]);
                setPhoneNumber(payload["phone_number"]);
                setEmail(payload["email"]);
            })
    });

    return { username, email, phoneNumber };
}

export default useUserInfo;