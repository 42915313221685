import React, {useContext, useState} from 'react';
import {AccountContext} from "../context/Account";
import {Button, Form, Loader} from "semantic-ui-react";

const Login = ( {setLoggedIn}) => {
    const {authenticate} = useContext(AccountContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        authenticate(username, password)
            .then(data => {
                console.log("logged in! ", data);
                setLoading(false);
                setLoggedIn(true);
            })
            .catch(err => {
                setLoading(false);
                console.error("Failed to login. ", err);
            })
    };

    return (
        <div className="p-8 mx-auto">
            {loading && <Loader active />}
            <h2>Alert leaders: please sign in.</h2>
            <Form onSubmit={onSubmit} className="">
                <Form.Field>
                    <label className="text-left">Username:</label>
                    <input type="text" onChange={e => setUsername(e.target.value)}/>
                </Form.Field>

                <Form.Field>
                    <label className="text-left">Password:</label>
                    <input type="password" onChange={e => setPassword(e.target.value)}/>
                </Form.Field>
                <Button primary className="float-right">Sign in</Button>
            </Form>
        </div>
    );
};

export default Login;
